import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import Link from '@mui/material/Link';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumbs = ({ items }) => (
  <MUIBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} sx={{ mb: 4 }}>
    <NextLink href="/" passHref>
      <Link variant="body2" underline="hover" color="link.muted">Home</Link>
    </NextLink>
    {items.map((item) => {
      if (item.href) {
        return (
          <NextLink key={item.href} href={item.href} passHref>
            <Link variant="body2" underline="hover" color="link.muted">{item.label}</Link>
          </NextLink>
        );
      }
      return <Typography variant="body2" key={item.label} color="link.muted">{item.label}</Typography>;
    })}
  </MUIBreadcrumbs>
);

Breadcrumbs.defaultProps = {
  items: [],
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.oneOfType([
        PropTypes.string, // e.g. /index
        PropTypes.shape({ // e.g. { pathname: '/article', query: { id: 1 } }
          pathname: PropTypes.string,
          query: PropTypes.object,
        }),
      ]),
    }).isRequired,
  ),
};

export default Breadcrumbs;
