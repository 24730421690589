import { useEffect } from 'react';
import { ValidatorForm as MUIValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';

const ValidatorForm = (props) => {
  const { children, submitOnEnter, ...inputProps } = props;

  const handleEnterKeyPress = (event) => {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!submitOnEnter) {
      window.addEventListener('keypress', handleEnterKeyPress);
    }

    return () => {
      if (!submitOnEnter) {
        window.removeEventListener('keypress', handleEnterKeyPress);
      }
    };
  }, []);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MUIValidatorForm {...inputProps}>
      {children}
    </MUIValidatorForm>
  );
};

ValidatorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  instantValidate: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  children: PropTypes.node,
  onError: PropTypes.func,
  debounceTime: PropTypes.number,
};

ValidatorForm.defaultProps = {
  instantValidate: false,
  submitOnEnter: false,
  onError: () => {},
  debounceTime: 0,
};

ValidatorForm.addValidationRule = MUIValidatorForm.addValidationRule;
ValidatorForm.getValidationRule = MUIValidatorForm.getValidationRule;
ValidatorForm.hasValidationRule = MUIValidatorForm.hasValidationRule;
ValidatorForm.removeValidationRule = MUIValidatorForm.removeValidationRule;

export default ValidatorForm;