import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SnackbarContent from '@mui/material/SnackbarContent';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material';

const PREFIX = 'ErrorSnackbar';

const classes = {
  error: `${PREFIX}-error`,
  iconVariant: `${PREFIX}-iconVariant`,
  message: `${PREFIX}-message`,
};

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  [`&.${classes.error}`]: {
    backgroundColor: theme.palette.error.dark,
    marginTop: theme.spacing(2),
  },
  [`& .${classes.iconVariant}`]: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  [`& .${classes.message}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ErrorSnackbar = ({ error, className }) => {
  if (!error) return null;

  return (
    <StyledSnackbarContent
      margin="normal"
      className={classNames(classes.error, className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <ErrorIcon className={classes.iconVariant} />{' '}
          {error || 'Something went wrong'}
        </span>
      }
    />
  );
};

ErrorSnackbar.defaultProps = {
  error: '',
  className: '',
};

ErrorSnackbar.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorSnackbar;
