import React from 'react';
import PropTypes from 'prop-types';
import MButton from '@mui/material/Button';
import MIconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Link from 'next/link';

class Button extends React.Component {
  static VARIANT_POSITIVE = 'BUTTON_VARIANT_POSITIVE';

  static VARIANT_NEUTRAL = 'BUTTON_VARIANT_NEUTRAL';

  static VARIANT_NEGATIVE = 'BUTTON_VARIANT_NEGATIVE';

  static VARIANT_DANGER = 'BUTTON_VARIANT_DANGER'

  getColorPropValue = () => {
    switch (this.props.variant) {
      case Button.VARIANT_POSITIVE: return 'primary';
      case Button.VARIANT_NEGATIVE: return 'secondary';
      case Button.VARIANT_DANGER: return 'error';
      default: return 'primary';
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  ButtonComponent = (props) => {
    if (this.props.iconButton) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <MIconButton {...props} />;
    }
    return (
      <MButton
        variant={this.props.highlighted ? 'contained' : 'outlined'}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  TooltipComponent = (props) => {
    if (this.props.title && !this.props.disabled) {
      return (
        <Tooltip title={this.props.title} disableInteractive>
          {props.children}
        </Tooltip>
      );
    }
    return (
      props.children
    );
  }

  withLink = (button) => {
    const { href, hrefAs, target, route, params } = this.props;
    if (!href && !route) {
      return button;
    }

    const LinkComponent = this.props.link;
    if (!LinkComponent) {
      return button;
    }

    return (
      <LinkComponent
        href={href}
        as={hrefAs}
        target={target}
        route={route}
        params={params}
      >
        {button}
      </LinkComponent>
    );
  }

  handleClick = (event) => {
    if (!this.props.disabled && typeof this.props.onClick === 'function') {
      this.props.onClick(event);
    }
  }

  render() {
    const { size, disabled, className, type, style, dataTid, children, sx } = this.props;
    return (
      <this.TooltipComponent>
        {this.withLink(
          <this.ButtonComponent
            onClick={this.handleClick}
            color={this.getColorPropValue()}
            size={size}
            disabled={disabled}
            className={className}
            type={type}
            style={style}
            sx={sx}
            data-tid={dataTid === '' ? null : dataTid}
          >
            {children}
          </this.ButtonComponent>
        )}
      </this.TooltipComponent>
    );
  }
}

Button.defaultProps = {
  variant: Button.VARIANT_NEUTRAL,
  disabled: false,
  iconButton: false,
  className: null,
  title: null,
  onClick: null,
  link: Link,
  highlighted: false,
  type: 'button',
  href: null,
  style: {},
  size: 'small',
  dataTid: '',
};

Button.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOf([
    Button.VARIANT_POSITIVE,
    Button.VARIANT_NEUTRAL,
    Button.VARIANT_NEGATIVE,
    Button.VARIANT_DANGER,
  ]),
  disabled: PropTypes.bool,
  iconButton: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  link: PropTypes.func,
  highlighted: PropTypes.bool,
  type: PropTypes.string,
  href: PropTypes.string,
  hrefAs: PropTypes.string,
  style: PropTypes.object,
  sx: PropTypes.object,
  size: PropTypes.string,
  target: PropTypes.string,
  route: PropTypes.string,
  params: PropTypes.object,
  dataTid: PropTypes.string,
};

export default Button;
