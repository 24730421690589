import React, { Component } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { TextValidator } from 'react-material-ui-form-validator';
import ValidatorForm from '../common/ValidatorForm';
import GoogleLogoIcon from './GoogleLogoIcon';
import ErrorSnackbar from '../common/ErrorSnackbar';
import Button from '../common/Button';

class LoginFormInit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember: false,
    };
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleOnRememberChange = () => this.setState((prevState) => ({ remember: !prevState.remember }));

  handleSubmit = () => this.props.onFormSubmit(this.state);

  render() {
    const { error, isLoading, onGsuiteSubmit } = this.props;
    const { email, password } = this.state;

    return (
      <ValidatorForm
        className="loginForm mt-1"
        onSubmit={this.handleSubmit}
        instantValidate={false}
      >
        <TextValidator
          id="email"
          label="Email Address"
          onChange={this.handleOnChange}
          name="email"
          value={email}
          margin="normal"
          autoComplete="email"
          variant="standard"
          fullWidth
          validators={['required', 'isEmail']}
          errorMessages={['Email is required', 'email is not valid']}
          data-tid="loginEmail"
        />
        <TextValidator
          id="password"
          label="Password"
          onChange={this.handleOnChange}
          name="password"
          type="password"
          variant="standard"
          value={password}
          margin="normal"
          autoComplete="current-password"
          fullWidth
          validators={['required']}
          errorMessages={['Password is required']}
          data-tid="loginPassword"
        />
        <div className="d-flex justify-between align-center">
          <FormControlLabel
            control={
              <Checkbox
                name="remember"
                value="remember"
                color="primary"
                onChange={this.handleOnRememberChange}
                data-tid="loginRememberMe"
              />
            }
            label="Remember me"
          />
          <Link href="/password-reset">
            <a className="passwordResetLink">Forgot password</a>
          </Link>
        </div>
        <ErrorSnackbar error={error} />
        <Grid container spacing={1} className="loginActions mt-1">
          <Grid item xs={12} md={6}>
            <Button
              type="submit"
              color="primary"
              isLoading={isLoading}
              className="loginButton"
              dataTid="loginButton"
            >
              Log in
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={onGsuiteSubmit}
              className="loginButton googleLogin"
              dataTid="loginGoogleButton"
            >
              <GoogleLogoIcon />
              <span>Login with Google</span>
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

LoginFormInit.propTypes = {
  onFormSubmit: PropTypes.func,
  onGsuiteSubmit: PropTypes.func,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default LoginFormInit;
